@import "tailwindcss/utilities";

.link-opacity {
  transition: opacity 0.2s ease-in;
}

.link-opacity:hover {
  opacity: 0.8;
}

.link-scale {
  transition: transform 0.2s ease-in;
  transform: scale3d(1, 1, 1) perspective(1px) translateZ(0);
}

.link-scale:hover {
  transform: scale3d(1.05, 1.05, 1.05) perspective(1px) translateZ(0);
}
