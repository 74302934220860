.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.top-\[var\(--navbar-height\)\] {
  top: var(--navbar-height);
}

.left-0 {
  left: 0px;
}

.right-0 {
  right: 0px;
}

.z-20 {
  z-index: 20;
}

.order-2 {
  order: 2;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.float-right {
  float: right;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-32 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mt-36 {
  margin-top: 9rem;
}

.mt-32 {
  margin-top: 8rem;
}

.mb-64 {
  margin-bottom: 16rem;
}

.-mt-8 {
  margin-top: -2rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.-mb-4 {
  margin-bottom: -1rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mb-32 {
  margin-bottom: 8rem;
}

.mr-auto {
  margin-right: auto;
}

.\!ml-2 {
  margin-left: 0.5rem !important;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.-mr-1 {
  margin-right: -0.25rem;
}

.box-content {
  box-sizing: content-box;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.\!hidden {
  display: none !important;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-4 {
  height: 1rem;
}

.h-3 {
  height: 0.75rem;
}

.h-full {
  height: 100%;
}

.max-h-80 {
  max-height: 20rem;
}

.\!min-h-0 {
  min-height: 0px !important;
}

.w-4 {
  width: 1rem;
}

.w-8 {
  width: 2rem;
}

.w-6 {
  width: 1.5rem;
}

.w-full {
  width: 100%;
}

.w-5 {
  width: 1.25rem;
}

.w-3 {
  width: 0.75rem;
}

.w-0 {
  width: 0px;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.flex-none {
  flex: none;
}

.flex-1 {
  flex: 1 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: var(--tw-transform);
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: var(--tw-transform);
}

.cursor-pointer {
  cursor: pointer;
}

.list-inside {
  list-style-position: inside;
}

.list-disc {
  list-style-type: disc;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-y-10 {
  row-gap: 2.5rem;
}

.gap-x-4 {
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}

.gap-y-0 {
  row-gap: 0px;
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.border {
  border-width: 1px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-t {
  border-top-width: 1px;
}

.border-l-8 {
  border-left-width: 8px;
}

.border-grey-light {
  --tw-border-opacity: 1;
  border-color: rgba(229, 229, 229, var(--tw-border-opacity));
}

.border-grey {
  --tw-border-opacity: 1;
  border-color: rgba(192, 192, 192, var(--tw-border-opacity));
}

.border-l-green-500 {
  --tw-border-opacity: 1;
  border-left-color: rgba(34, 197, 94, var(--tw-border-opacity));
}

.border-l-yellow-400 {
  --tw-border-opacity: 1;
  border-left-color: rgba(250, 204, 21, var(--tw-border-opacity));
}

.bg-brown {
  --tw-bg-opacity: 1;
  background-color: rgba(60, 58, 54, var(--tw-bg-opacity));
}

.bg-grey {
  --tw-bg-opacity: 1;
  background-color: rgba(192, 192, 192, var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 179, 8, var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(34, 197, 94, var(--tw-bg-opacity));
}

.p-2 {
  padding: 0.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pt-12 {
  padding-top: 3rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-century-gothic {
  font-family: Century Gothic;
}

.font-gill-sans-nova {
  font-family: Gill Sans Nova;
}

.font-arial {
  font-family: Arial;
}

.font-verdana {
  font-family: Verdana;
}

.font-georiga {
  font-family: Georgia;
}

.font-palatino-linotype {
  font-family: Palatino Linotype;
}

.font-constantia {
  font-family: Constantia;
}

.font-times-new-roman {
  font-family: Times New Roman;
}

.font-futura {
  font-family: Futura;
}

.font-gill-sans {
  font-family: Gill Sans;
}

.font-helvetica-neue {
  font-family: Helvetica Neue;
}

.font-big-caslon {
  font-family: Big Caslon;
}

.font-hoefler-text {
  font-family: Hoefler Text;
}

.font-didot {
  font-family: Didot;
}

.font-times {
  font-family: Times;
}

.font-allumi {
  font-family: 'AllumiSpe', San Francisco, Corbel, Verdana, sans-serif;
}

.font-mencken-head {
  font-family: 'MenckenSpeHead', Georgia, serif;
}

.font-mencken-text {
  font-family: 'MenckenSpeText', Georgia, serif;
}

.font-ysans-demi {
  font-family: 'YsansSpeDemi', San Francisco, Corbel, Verdana, sans-serif;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-\[1\.2rem\] {
  font-size: 1.2rem;
}

.text-\[14px\] {
  font-size: 14px;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.not-italic {
  font-style: normal;
}

.leading-\[2\.7rem\] {
  line-height: 2.7rem;
}

.leading-\[18px\] {
  line-height: 18px;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-normal {
  line-height: 1.5;
}

.leading-none {
  line-height: 1;
}

.text-grey-dark {
  --tw-text-opacity: 1;
  color: rgba(115, 115, 115, var(--tw-text-opacity));
}

.text-red {
  --tw-text-opacity: 1;
  color: rgba(201, 58, 51, var(--tw-text-opacity));
}

.text-grey {
  --tw-text-opacity: 1;
  color: rgba(192, 192, 192, var(--tw-text-opacity));
}

.text-brown {
  --tw-text-opacity: 1;
  color: rgba(60, 58, 54, var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.underline {
  text-decoration: underline;
}

.line-through {
  text-decoration: line-through;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0,0,0,0)), var(--tw-ring-shadow, 0 0 rgba(0,0,0,0)), var(--tw-shadow);
}

.filter {
  filter: var(--tw-filter);
}

.link-opacity {
  transition: opacity 0.2s ease-in;
}

.link-opacity:hover {
  opacity: 0.8;
}

.link-scale {
  transition: transform 0.2s ease-in;
  transform: scale3d(1, 1, 1) perspective(1px) translateZ(0);
}

.link-scale:hover {
  transform: scale3d(1.05, 1.05, 1.05) perspective(1px) translateZ(0);
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:bg-grey-dark:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(115, 115, 115, var(--tw-bg-opacity));
}

.hover\:bg-black\/100:hover {
  background-color: rgba(0, 0, 0, 1);
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0,0,0,0));
}

.focus\:ring-white:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}

.peer:checked ~ .peer-checked\:text-red {
  --tw-text-opacity: 1;
  color: rgba(201, 58, 51, var(--tw-text-opacity));
}

@media (min-width: 525px) {

  .tablet-portrait\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .tablet-portrait\:gap-x-8 {
    -moz-column-gap: 2rem;
         column-gap: 2rem;
  }
}

@media (min-width: 769px) {

  .tablet\:left-auto {
    left: auto;
  }

  .tablet\:order-3 {
    order: 3;
  }

  .tablet\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .tablet\:mt-40 {
    margin-top: 10rem;
  }

  .tablet\:mr-16 {
    margin-right: 4rem;
  }

  .tablet\:mt-0 {
    margin-top: 0px;
  }

  .tablet\:mr-8 {
    margin-right: 2rem;
  }

  .tablet\:mb-40 {
    margin-bottom: 10rem;
  }

  .tablet\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .tablet\:mt-16 {
    margin-top: 4rem;
  }

  .tablet\:ml-3 {
    margin-left: 0.75rem;
  }

  .tablet\:-mr-2 {
    margin-right: -0.5rem;
  }

  .tablet\:mt-28 {
    margin-top: 7rem;
  }

  .tablet\:mb-32 {
    margin-bottom: 8rem;
  }

  .tablet\:block {
    display: block;
  }

  .tablet\:inline-block {
    display: inline-block;
  }

  .tablet\:flex {
    display: flex;
  }

  .tablet\:inline-flex {
    display: inline-flex;
  }

  .tablet\:list-item {
    display: list-item;
  }

  .tablet\:hidden {
    display: none;
  }

  .tablet\:w-4\/12 {
    width: 33.333333%;
  }

  .tablet\:w-8\/12 {
    width: 66.666667%;
  }

  .tablet\:max-w-xl {
    max-width: 36rem;
  }

  .tablet\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .tablet\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .tablet\:items-start {
    align-items: flex-start;
  }

  .tablet\:justify-between {
    justify-content: space-between;
  }

  .tablet\:gap-x-8 {
    -moz-column-gap: 2rem;
         column-gap: 2rem;
  }

  .tablet\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .tablet\:pt-4 {
    padding-top: 1rem;
  }

  .tablet\:pr-4 {
    padding-right: 1rem;
  }

  .tablet\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

@media (min-width: 1025px) {

  .desktop\:order-2 {
    order: 2;
  }

  .desktop\:order-none {
    order: 0;
  }

  .desktop\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .desktop\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .desktop\:mt-40 {
    margin-top: 10rem;
  }

  .desktop\:mb-16 {
    margin-bottom: 4rem;
  }

  .desktop\:mt-28 {
    margin-top: 7rem;
  }

  .desktop\:mb-40 {
    margin-bottom: 10rem;
  }

  .desktop\:mt-32 {
    margin-top: 8rem;
  }

  .desktop\:-mt-10 {
    margin-top: -2.5rem;
  }

  .desktop\:mb-0 {
    margin-bottom: 0px;
  }

  .desktop\:ml-8 {
    margin-left: 2rem;
  }

  .desktop\:mr-16 {
    margin-right: 4rem;
  }

  .desktop\:mt-24 {
    margin-top: 6rem;
  }

  .desktop\:mb-8 {
    margin-bottom: 2rem;
  }

  .desktop\:mr-8 {
    margin-right: 2rem;
  }

  .desktop\:mb-72 {
    margin-bottom: 18rem;
  }

  .desktop\:ml-1 {
    margin-left: 0.25rem;
  }

  .desktop\:block {
    display: block;
  }

  .desktop\:flex {
    display: flex;
  }

  .desktop\:list-item {
    display: list-item;
  }

  .desktop\:hidden {
    display: none;
  }

  .desktop\:w-4\/6 {
    width: 66.666667%;
  }

  .desktop\:flex-none {
    flex: none;
  }

  .desktop\:flex-1 {
    flex: 1 1;
  }

  .desktop\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .desktop\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .desktop\:flex-row {
    flex-direction: row;
  }

  .desktop\:flex-col {
    flex-direction: column;
  }

  .desktop\:flex-wrap {
    flex-wrap: wrap;
  }

  .desktop\:items-start {
    align-items: flex-start;
  }

  .desktop\:items-end {
    align-items: flex-end;
  }

  .desktop\:items-center {
    align-items: center;
  }

  .desktop\:items-baseline {
    align-items: baseline;
  }

  .desktop\:justify-between {
    justify-content: space-between;
  }

  .desktop\:gap-12 {
    gap: 3rem;
  }

  .desktop\:gap-x-12 {
    -moz-column-gap: 3rem;
         column-gap: 3rem;
  }

  .desktop\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .desktop\:text-\[18px\] {
    font-size: 18px;
  }
}

